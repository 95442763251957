import * as React from 'react';
import { Button, Create, DataProviderContext, Edit, LinearProgress, SaveButton, SimpleForm, useNotify, useRedirect } from "react-admin"
import { CustomList, DefaultValueField } from "../Generic/bq-form-components"
import { BQSection, BQSideBySide, BQToolbar } from "../Generic/BQUI"
import { getDateTime } from "../../utils/textUtils"
import BQCRUDBuilder from '../Generic/BQCRUDComponent';
import { useParams } from 'react-router-dom';
import { OUSelectInput } from '../organizationUnitSelector.component';
import { BQDropDown, BQInput } from '../Generic/bq-input';
import { getFromCache, useGetAppSettings } from '../../utils/globals';
import { bqAuthData } from '../../utils/bq-auth';
import { BQFormExporter } from '../../utils/bq_form_export';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { validate, validateNumber } from '../../utils/validations';
import { CustomToolbar, SerialOrRirInput } from './inventoryUI';
import { useBQGetList } from '../Generic/hooks';
import { createRefreshToken, handleLocationValue } from '../../utils/inventoryTools';
import { SoftDeleteListButton } from '../Generic/SoftDeleteListButton';

import { useFormContext } from 'react-hook-form';
import { BQExporter } from '../../utils/bq_export';
import { choicesFromDictionary } from '../../utils/constants';

const initialFilter = {
    serialNumber: '',
    itemType: '',
    currentLocation: '',
    status: ''
}

const InventoryFilter = ({ onSearch, ...props }) => {
    const formContext = useFormContext()
    const [filter, setFilter] = React.useState(initialFilter)
    const { itemTypes, itemStatuses } = useGetAppSettings();
    const notify = useNotify();
    const classes = getFromCache('bqClasses');

    const search = () => {
        setFilter({ ...filter, search: undefined });
        const filterQuery = {};
        const filterObjectKeys = Object.keys(filter)?.filter(key => key !== 'search');
        if (filterObjectKeys.length) {
            filterObjectKeys.forEach(key => {
                const value = filter[key]?.toString()?.trim();
                if (value) {
                    switch (key) {
                        case 'from':
                        case 'to':
                            filterQuery[key] = new Date(value).getTime();
                            break;
                        default:
                            filterQuery[key] = value;
                            break;
                    }
                }
            });
            if (filter["from"] && filter["to"] && filter["from"] > filter["to"]) {
                notify('Date range is wrong. "From" value should be lower than "To" value', { type: 'warning' });
            } else if (!filterObjectKeys.filter(key => key !== 'from' && key !== 'to' && key !== 'refresh' && filter[key]?.toString()?.trim())?.length) {
                notify('Please use at least one filter (other than a time range)', { type: 'warning' });
            } else {
                filterQuery.refresh = createRefreshToken();
                onSearch?.(filterQuery);
            }
        }
    };

    React.useEffect(() => {
        if (filter.search) {
            search();
        }
    }, [filter]);

    const clearSearch = () => {
        formContext.reset(initialFilter);
        setFilter({ ...initialFilter, search: true });
    };

    return !!itemTypes && (
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <tr>
                <td>
                    <BQSection title="Inventory Filter" style={{ maxWidth: '100%' }}>
                        <BQSideBySide>
                            <table className={classes.BQSectionContent} title={'Filter'}>
                                <BQInput
                                    source="serialNumber"
                                    autoComplete={null}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setFilter({ ...filter, serialNumber: value });
                                    }}
                                />
                                <BQDropDown
                                    source="itemType"
                                    choices={itemTypes}
                                    onChange={({ selection }) => {
                                        setFilter({ ...filter, itemType: selection.id });
                                    }}
                                />
                            </table>
                            <table className={classes.BQSectionContent} title={'Filter'}>
                                <OUSelectInput
                                    source="currentLocation"
                                    notRequired
                                    onChange={({ selection }) => {
                                        setFilter({ ...filter, currentLocation: selection.name });
                                    }}
                                />
                                <BQDropDown
                                    source="status"
                                    choices={choicesFromDictionary(itemStatuses)}
                                    defaultValue={initialFilter.status}
                                    onChange={({ selection }) => setFilter({ ...filter, status: selection.id })}
                                />
                            </table>
                        </BQSideBySide>
                    </BQSection>
                </td>
            </tr>
            <tr>
                <td style={{ margin: 'auto', textAlign: 'center' }}>
                    <SaveButton
                        id="btSearch"
                        alwaysEnable={true}
                        style={{ boxShadow: 'none' }}
                        label="Search"
                        onClick={() => setFilter({ ...filter, search: true })}
                    />
                    <Button
                        id="btClear"
                        type="reset"
                        onClick={clearSearch}
                    >
                        Clear
                    </Button>
                </td>
            </tr>
        </table>
    );
};

export const InventoryList = () => {
    const [listFilter, setListFilter] = React.useState(initialFilter)
    const [refreshToken, setRefreshToken] = React.useState()
    const listData = useBQGetList({ resource: 'inventoryItems', filter: listFilter, refreshToken })

    const search = (filter) => {
        const { refresh } = filter
        delete filter.refresh

        const reconstructedFilter = filter
        setListFilter(reconstructedFilter)
        setRefreshToken(refresh)
    }

    if (!listData.isLoading) {
        const listsByType = {}
        listData.data.forEach(item => {
            if (!listsByType[item.itemType]) {
                listsByType[item.itemType] = []
            }
            listsByType[item.itemType].push(item)
        })

        return <>
            <SimpleForm toolbar={null}>
                <InventoryFilter onSearch={search} />
            </SimpleForm>
            <InventoryItemsList {...{
                itemsList: listData.data,
                sortField: 'updatedAt',
                onItemChanged: () => setRefreshToken(createRefreshToken()),
            }} />
        </>
    }
    return <LinearProgress />
}

export const InventoryCreate = (props) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { onItemCreated } = props
    const notify = useNotify()
    const { itemTypes, conditions, packageTypes } = useGetAppSettings()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleSave = (e) => {
        notify('Item created successfully');
        setOpen(false);
        onItemCreated?.(e)
    };

    const [tracedBy, setTracedBy] = React.useState(null)

    return itemTypes && (
        <div style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Create New Item
            </Button>
            <Dialog open={open} maxWidth={1024} onClose={handleClose} aria-labelledby="form-dialog-title"  >
                <DialogTitle id="form-dialog-title" style={{ width: '800px' }}>New Inventory Item</DialogTitle>
                <DialogContent>
                    <Create redirect={false} mutationOptions={{
                        onSuccess: handleSave
                    }} resource="inventoryItems">
                        <SimpleForm toolbar={
                            <CustomToolbar onCancel={handleClose} />
                        }>
                            <table style={{ width: '100%' }}>
                                <BQDropDown
                                    source="itemType"
                                    label="Item type"
                                    choices={itemTypes}
                                    unsorted
                                    validate={validate}
                                    onChange={(data) => {
                                        const { source: itemSource, selection, formContext } = data
                                        setTracedBy(selection?.tracedBy)
                                        let inputTemplateSource = null
                                        let inputTemplateRemoveSource = null
                                        switch (selection?.tracedBy) {
                                            case 'SERIAL_NUMBER':
                                                inputTemplateSource = `serialNumber`
                                                inputTemplateRemoveSource = `rirNumber`
                                                break
                                            case 'RIR':
                                                inputTemplateSource = `rirNumber`
                                                inputTemplateRemoveSource = `serialNumber`
                                                formContext.setValue('quantity', 1)
                                                break
                                        }
                                        if (inputTemplateSource) {
                                            formContext.setValue(inputTemplateRemoveSource, null)
                                            formContext.setValue(inputTemplateSource, selection?.inputTemplate, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                                            setTimeout(() => {
                                                document.getElementById(`${inputTemplateSource}_input`)?.focus()
                                            }, 1)
                                        }
                                    }}
                                />
                                <SerialOrRirInput {...{
                                    isNewItem: true,
                                    label: 'SN / RIR / Identifier (where applicable)',
                                    source: '',
                                    packageSource: ''
                                }} />
                                {tracedBy === 'RIR' && <BQInput type="number" source="quantity" label="Quantity" validate={validateNumber} min={1} />}
                                {isSuperUser && <OUSelectInput source="currentLocation" useNameAsValue />}
                            </table>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const InventoryItemsList = ({ itemsList, sortField, onItemChanged }) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { itemTypes, itemStatuses } = useGetAppSettings()
    const bqExporter = new BQExporter()
    const redirect = useRedirect()
    return !!itemTypes &&
        <CustomList
            {...{
                title: `Inventory list`,
                maxHeight: 512,
                data: itemsList,
                sortField,
                sortOrder: 'ASC',
                showCounter: true,
                exporter: bqExporter,
                rowClick: (e) => redirect(`/inventoryItems/${e}`),
                component: (
                    <div><table style={{ marginLeft: 'auto', marginRight: '0' }}><tr>
                        <td>{isSuperUser ? <InventoryCreate onItemCreated={onItemChanged} /> : null}</td>
                        <td>{bqExporter && <Button onClick={() => bqExporter.exportData(`list`)} label="Export" />}</td>
                    </tr>
                    </table>
                    </div>
                )
            }}>

            <DefaultValueField source="itemType" label="Item Type" value={(v) => itemTypes.find(item => item.id === v)?.name} />
            <DefaultValueField source="serialNumber" label="Serial Number (Where applicable)" value={(v, record) => {
                const itemTypeRecord = itemTypes.find(item => item.id === record.itemType)
                const text = record.serialNumber
                return itemTypeRecord?.tracedBy == 'SERIAL_NUMBER' ? text : (isSuperUser ? <span style={{ opacity: 0.3 }}>{record.rirNumber} ({text})</span> : '')
            }} />
            <DefaultValueField source="currentLocation" label="Location" value={handleLocationValue} />
            <DefaultValueField source="status" value={(v) => itemStatuses[v]?.name || v} />
            {isSuperUser && <SoftDeleteListButton
                id="delete_button"
                resoureName="inventoryItems"
                nameField={record => itemTypes.find(item => item.id === record?.itemType)?.name}
                title="Delete"
                onDelete={onItemChanged}
            />}
        </CustomList>
}

export const InventoryUpdate = (props) => {
    const classes = getFromCache('bqClasses');
    const { onItemUpdated } = props
    const notify = useNotify()
    const { itemStatuses } = useGetAppSettings()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleSave = (e) => {
        notify('Item updated successfully');
        setOpen(false);
        onItemUpdated?.(e)
    };

    return itemStatuses && (
        <div style={{ textAlign: 'right' }}>
            <div className={classes.editButton} onClick={handleClickOpen} title="Edit status of item"></div>
            <Dialog open={open} maxWidth={1024} onClose={handleClose} aria-labelledby="form-dialog-title"  >
                <DialogTitle id="form-dialog-title" style={{ width: '800px' }}>Edit status of item</DialogTitle>
                <DialogContent>
                    <Edit
                        redirect={false}
                        mutationMode='pessimistic'
                        transform={(data) => {
                            const { id, serialNumber, status, currentLocation } = data
                            return {
                                id,
                                serialNumber,
                                status,
                                currentLocation
                            }
                        }}
                        mutationOptions={{
                            onSuccess: handleSave
                        }}
                        resource="inventoryItems"
                    >
                        <SimpleForm toolbar={
                            <CustomToolbar onCancel={handleClose} />
                        }>
                            <table style={{ width: '100%' }}>
                                <BQInput source="serialNumber" />
                                <BQDropDown
                                    source="status"
                                    label="Item status"
                                    choices={choicesFromDictionary(itemStatuses)}
                                    unsorted
                                    validate={validate}
                                    onChange={(data) => {
                                        const { source: itemSource, selection, formContext } = data
                                        formContext.setValue('status', selection.id)
                                    }}
                                />
                                <OUSelectInput source="currentLocation" useNameAsValue />
                            </table>
                        </SimpleForm>
                    </Edit>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const bqExporter = new BQFormExporter()
export const InventoryView = () => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { itemTypes, itemStatuses, packageTypes, conditions, actions } = useGetAppSettings()
    const { id } = useParams()
    const dataProvider = React.useContext(DataProviderContext);
    const redirect = useRedirect()
    const [formState, setFormState] = React.useState({ isLoading: true })

    React.useEffect(() => {
        dataProvider.getOne('inventoryItems', { id }).then(response => {
            setFormState({
                isLoading: false,
                data: [response?.data],
            })
        })
    }, [])

    const item = formState?.data?.[0]

    const title = item && `${item.itemType} #${item.serialNumber}`
    return itemTypes && <>
        {item && <>
            <form>
                <BQToolbar label={title} hideButtons simpleBack
                    onBack={() => history.back()}
                    forceExporter
                    exporterLabel="Export form"
                    exporter={() => bqExporter.exportForm({ formType: 'item', data: formState?.data?.[0], itemTypes, packageTypes, conditions, itemStatuses, actions })}
                />
            </form>
            <CustomList
                {...{
                    title: 'Item details',
                    noCount: true,
                    data: formState?.data,
                    sortField: 'updatedAt',
                    sortOrder: 'ASC',
                    style: { maxWidth: '100%', marginTop: '32px' }
                }}>
                <DefaultValueField source="updatedAt" value={v => getDateTime(new Date(v), true)} />
                <DefaultValueField source="itemType" label="Item Type" value={(v) => itemTypes.find(item => item.id === v)?.name || v} />
                <DefaultValueField source="serialNumber" label="Serial Number" />
                <DefaultValueField source="rirNumber" label="RIR Number (Where applicable)" />
                <DefaultValueField source="currentLocation" label="Location" value={handleLocationValue} />
                <DefaultValueField source="status" value={(v) => itemStatuses[v]?.name || v} />
                {isSuperUser && <DefaultValueField value={(v, record) => <InventoryUpdate onItemUpdated={() => window.location.reload()} />} />}
            </CustomList>
            <ItemHistory inventoryItem={item} />
        </>}
    </>
}

export const ItemHistory = ({ inventoryItem }) => {
    const { itemTypes, itemStatuses, actions } = useGetAppSettings()
    const itemTypeData = itemTypes?.find(item => item.id === inventoryItem.itemType)
    return itemTypes &&
        <CustomList
            {...{
                title: `${itemTypeData?.name} ${itemTypeData.tracedBy === 'SERIAL_NUMBER' ? `(${inventoryItem.serialNumber})` : ''} - Item History`,
                data: inventoryItem?.log?.filter(item => item.location),
                sortField: 'timestamp',
                sortOrder: 'ASC'
            }}>
            <DefaultValueField source="timestamp" value={v => getDateTime(new Date(v), true)} />
            <DefaultValueField source="location" value={handleLocationValue} />
            <DefaultValueField source="status" value={(v) => itemStatuses[v]?.name || v} />
            <DefaultValueField source="action" value={(v) => actions[v]?.name || v} />
            <DefaultValueField source="userId" label="User" />
        </CustomList>
}

export default BQCRUDBuilder({
    Create: null,
    Edit: InventoryView,
    List: InventoryList,
})